var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-group"},[_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`Título de la ${_vm.$getVisibleNames(
        'evaluations2.rubric',
        false,
        'Pauta'
      )}`,"label-for":"input-name","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"input-name","state":_vm.validateState('title'),"aria-describedby":"input-name-feedback","size":"sm"},model:{value:(_vm.$v.rubric.title.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric.title, "$model", $$v)},expression:"$v.rubric.title.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`Descripción de la ${_vm.$getVisibleNames(
        'evaluations2.rubric',
        false,
        'Pauta'
      )}`,"label-for":"input-description","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
          `Editar Descripción de la ${_vm.$getVisibleNames(
            'evaluations2.rubric',
            false,
            'Pauta'
          )}`
        ),expression:"\n          `Editar Descripción de la ${$getVisibleNames(\n            'evaluations2.rubric',\n            false,\n            'Pauta'\n          )}`\n        ",modifiers:{"v-secondary":true}}],staticClass:"rubric-form-description rich-text-content mb-2",domProps:{"innerHTML":_vm._s(_vm.$v.rubric.description.$model)},on:{"click":function($event){return _vm.$bvModal.show(`edit-rubric-description-modal-${_vm.rubric.id}`)}}}),_c('b-modal',{attrs:{"id":`edit-rubric-description-modal-${_vm.rubric.id}`,"title":`Editar Descripción de la ${_vm.$getVisibleNames(
          'evaluations2.rubric',
          false,
          'Pauta'
        )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.rubric,"Text":_vm.rubric.description},on:{"save":_vm.patchRubric,"close":function($event){return _vm.$bvModal.hide(`edit-rubric-description-modal-${_vm.rubric.id}`)}}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-description-feedback"}},[_vm._v("Este campo es opcional.")])],1),(!_vm.rubric.rubric_type_default)?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`${_vm.$getVisibleNames(
        'evaluations2.rubricpreset',
        false,
        'Modelo de Pauta'
      )}`,"label-for":"select-type","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-block w-100"},[_c('b-form-select',{attrs:{"id":"select-type","value-field":"id","text-field":"label","state":_vm.validateStateType('preset'),"options":_vm.rubricPresetsOptions,"aria-describedby":"select-type-feedback","size":"sm"},on:{"change":_vm.rubricTypePresetChoice},model:{value:(_vm.$v.rubric_type_default.preset.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_type_default.preset, "$model", $$v)},expression:"$v.rubric_type_default.preset.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"select-type-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),(isNaN(_vm.rubric.id))?_c('div',{staticClass:"ml-1 mr-1 container-btn-presets"},[(_vm.rubric_type_default.preset != null)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
              `Previsualizar ${_vm.$getVisibleNames(
                'evaluations2.rubricpreset',
                false,
                'Modelo de Pauta'
              )}`
            ),expression:"\n              `Previsualizar ${$getVisibleNames(\n                'evaluations2.rubricpreset',\n                false,\n                'Modelo de Pauta'\n              )}`\n            ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-0",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.$bvModal.show(`modal-view-rubric-preset-container-${_vm.test_id}`)}}},[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"eye","scale":"1"}})],1):_vm._e(),(_vm.user && (_vm.user.groups.includes(1) || _vm.user.is_superuser))?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
              `Crear ${_vm.$getVisibleNames(
                'evaluations2.rubricpreset',
                false,
                'Modelo de Pauta'
              )}`
            ),expression:"\n              `Crear ${$getVisibleNames(\n                'evaluations2.rubricpreset',\n                false,\n                'Modelo de Pauta'\n              )}`\n            ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-0",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-created-rubric-preset-form-${_vm.test_id}`)}}}):_vm._e()],1):_vm._e()])]):_vm._e(),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`${_vm.$getVisibleNames(
        'evaluations.observationachievement',
        false,
        'Niveles de Logro'
      )} por`,"label-for":"show_percentage_sign","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"}},[_c('b-form-select',{attrs:{"id":"show_percentage_sign","state":_vm.validateState('show_percentage_sign'),"options":_vm.showPercentageSigns,"value-field":"value","text-field":"label","aria-describedby":"show_percentage_sign-feedback","size":"sm"},model:{value:(_vm.$v.rubric.show_percentage_sign.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric.show_percentage_sign, "$model", $$v)},expression:"$v.rubric.show_percentage_sign.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"show_percentage_sign-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`Agregar Descriptor a ${_vm.$getVisibleNames(
        'evaluations.observationachievement',
        false,
        'Niveles de Logro'
      )}`,"label-for":"allow_criteria_performance_level_input","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"}},[_c('b-form-select',{attrs:{"id":"allow_criteria_performance_level_input","state":_vm.validateState('allow_criteria_performance_level_input'),"options":_vm.showPerformanceLevelInput,"value-field":"value","text-field":"label","aria-describedby":"allow_criteria_performance_level_input-feedback","size":"sm"},model:{value:(_vm.$v.rubric.allow_criteria_performance_level_input.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric.allow_criteria_performance_level_input, "$model", $$v)},expression:"$v.rubric.allow_criteria_performance_level_input.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"allow_criteria_performance_level_input-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),(
        _vm.evaluativeAgent &&
        (['duoc_coevaluacion_evaluative_agent'].includes(
          _vm.evaluativeAgent.internal_use_id
        ) ||
          _vm.$equals(_vm.evaluativeAgent.name, 'Coevaluación'))
      )?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"input-evaluatee_can_see_coevaluator","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"block-title-container"},[_c('div',[_vm._v("Mostrar Coevaluador")]),_c('div',{staticClass:"ml-2"},[_c('InfoTooltip',{attrs:{"tooltip_text":`Muestra al evaluado quién fue la persona que lo evaluó.`}})],1)])]},proxy:true}],null,false,3822367812)},[_c('div',[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"size":"md"},model:{value:(_vm.rubric.evaluatee_can_see_coevaluator),callback:function ($$v) {_vm.$set(_vm.rubric, "evaluatee_can_see_coevaluator", $$v)},expression:"rubric.evaluatee_can_see_coevaluator"}})],1)]):_vm._e(),(
        _vm.evaluativeAgent &&
        (['duoc_coevaluacion_evaluative_agent'].includes(
          _vm.evaluativeAgent.internal_use_id
        ) ||
          _vm.$equals(_vm.evaluativeAgent.name, 'Coevaluación'))
      )?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"input-evaluatee_can_see_coevaluator","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"block-title-container"},[_c('div',[_vm._v("Tipo de coevaluación")]),(!_vm.existEvaluateeTest)?_c('div',{staticClass:"ml-2"},[_c('InfoTooltip',{attrs:{"tooltip_text":`Al seleccionar múltiple, permite seleccionar varios coevaluados aunque se repitan en una coevaluación.`}})],1):_vm._e()])]},proxy:true}],null,false,2104247784)},[(!_vm.existEvaluateeTest)?_c('div',[_c('b-form-select',{staticClass:"mt-1",attrs:{"id":"select-type","value-field":"id","text-field":"value","state":_vm.validateState('coevaluation_type'),"options":_vm.rubric_coevaluation_type_choices,"aria-describedby":"select-type-feedback","size":"sm"},model:{value:(_vm.$v.rubric.coevaluation_type.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric.coevaluation_type, "$model", $$v)},expression:"$v.rubric.coevaluation_type.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"select-type-feedback"}},[_vm._v("Este campo es obligatorio.")])],1):_c('div',{staticClass:"ml-2 mt-2"},[_vm._v(" "+_vm._s(( _vm.rubric_coevaluation_type_choices.find( (x) => x.id == _vm.rubric.coevaluation_type ) || {} ).value || "")+" ")])]):_vm._e(),(_vm.institution && _vm.institution.internal_use_id != 'duoc_uc')?[(
          _vm.evaluativeAgent &&
          _vm.test &&
          _vm.test.student_can_auto_evaluate &&
          (['duoc_autoevaluacion_evaluative_agent'].includes(
            _vm.evaluativeAgent.internal_use_id
          ) ||
            _vm.$equals(_vm.evaluativeAgent.name, 'Autoevaluación'))
        )?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"input-evaluate_autoevaluation","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"title-method"},[_vm._v("Seleccione el método:")])]},proxy:true}],null,false,2615806742)}):_vm._e(),(
          _vm.evaluativeAgent &&
          _vm.test &&
          _vm.test.student_can_auto_evaluate &&
          (['duoc_autoevaluacion_evaluative_agent'].includes(
            _vm.evaluativeAgent.internal_use_id
          ) ||
            _vm.$equals(_vm.evaluativeAgent.name, 'Autoevaluación'))
        )?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"input-evaluate_autoevaluation","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"block-title-container"},[_c('b-icon',{staticClass:"mt-1",attrs:{"icon":"dot"}}),_c('div',[_vm._v("Método de autoevaluación congruente (Mac)")]),_c('div',{staticClass:"ml-2"},[_c('InfoTooltip',{attrs:{"tooltip_text":`La nota depende de la coincidencia de notas del Evaluador y el que se autoevalúa.`}})],1)],1)]},proxy:true}],null,false,877654367)},[_c('div',[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"size":"md"},on:{"change":_vm.changeEvaluateeAutoevaluation},model:{value:(_vm.rubric.evaluate_autoevaluation),callback:function ($$v) {_vm.$set(_vm.rubric, "evaluate_autoevaluation", $$v)},expression:"rubric.evaluate_autoevaluation"}})],1)]):_vm._e(),(
          _vm.evaluativeAgent &&
          !_vm.rubric.evaluate_autoevaluation &&
          ((_vm.test &&
            _vm.test.student_can_auto_evaluate &&
            (['duoc_autoevaluacion_evaluative_agent'].includes(
              _vm.evaluativeAgent.internal_use_id
            ) ||
              _vm.$equals(_vm.evaluativeAgent.name, 'Autoevaluación'))) ||
            ['duoc_coevaluacion_evaluative_agent'].includes(
              _vm.evaluativeAgent.internal_use_id
            ) ||
            _vm.$equals(_vm.evaluativeAgent.name, 'Coevaluación'))
        )?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"input-student_weighing","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"block-title-container"},[(
                _vm.evaluativeAgent &&
                !_vm.rubric.evaluate_autoevaluation &&
                (['duoc_autoevaluacion_evaluative_agent'].includes(
                  _vm.evaluativeAgent.internal_use_id
                ) ||
                  _vm.$equals(_vm.evaluativeAgent.name, 'Autoevaluación'))
              )?_c('b-icon',{staticClass:"mt-1",attrs:{"icon":"dot"}}):_vm._e(),_c('div',[_vm._v("Método de calificación ponderado")]),_c('div',{staticClass:"ml-2"},[_c('InfoTooltip',{attrs:{"tooltip_text":`El porcentaje indica la ponderación de la calificación que se autoasigna el estudiante, donde 0 es sin relevancia y 100 su máxima.`}})],1)],1)]},proxy:true}],null,false,3003445266)},[_c('b-form-input',{attrs:{"id":"input-student_weighing","state":_vm.validateState('student_weighing'),"aria-describedby":"input-student_weighing-feedback","size":"sm","type":"number"},model:{value:(_vm.$v.rubric.student_weighing.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric.student_weighing, "$model", $$v)},expression:"$v.rubric.student_weighing.$model"}}),(
            _vm.rubric.student_weighing >= 0 && _vm.rubric.student_weighing <= 100
          )?_c('div',{staticClass:"mb-2 alert-student-weighing"},[_vm._v(" El estudiante posee un peso en la ponderación de "),_c('strong',[_vm._v(_vm._s(_vm.rubric.student_weighing)+"%")]),_vm._v(" en la evaluación, lo que significa que el profesor tiene el "),_c('strong',[_vm._v(_vm._s(100 - _vm.rubric.student_weighing)+"%")]),_vm._v(" restante. ")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"id":"input-student_weighing-feedback"}},[(
              _vm.rubric.student_weighing > 100 || _vm.rubric.student_weighing < 0
            )?_c('span',[_vm._v(" Este campo debe ser un valor entre 0 y 100. ")]):_c('span',[_vm._v(" Este campo debe ser un número entero positivo. ")])])],1):_vm._e()]:_vm._e(),_c('b-modal',{attrs:{"id":`modal-view-rubric-preset-container-${_vm.test_id}`,"title":`Tipos de Instrumentos`,"size":"xl","hide-footer":""}},[_c('RubricPresetPreview',{attrs:{"rubric_preset_id":_vm.rubric_type_default.preset}})],1),_c('b-modal',{attrs:{"id":`modal-created-rubric-preset-form-${_vm.test_id}`,"title":`Crear ${_vm.$getVisibleNames(
        'evaluations2.rubricpreset',
        false,
        'Modelo de Pauta'
      )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('RubricPresetForm',{attrs:{"show_rubric_preset":true},on:{"created":_vm.createdRubricPreset}})],1),_c('div',{staticClass:"container-buttons"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.saveRubric}},[_vm._v("Guardar")])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }